import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import Menubar from '../components/Menubar';

import config from '../../config';
import Footer from '../components/Footer';
import small_icon from '../assets/images/small_icon.png';
import demo from '../assets/images/demo.gif';
import popup from '../assets/images/popup.png';
import menubar from '../assets/images/menubar.png';

import { StaticQuery, graphql } from 'gatsby';

const scrollLinks = [
    {
        name: 'About',
        id: 'about',
    },
    {
        name: 'Use',
        id: 'use',
    },
    {
        name: 'Subscribe',
        id: 'subscribe',
    }
];

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    this.state = { showForm: true };

    this.handleForm = this.handleForm.bind(this);
  }

  handleForm() {
    //this.submit();
    this.setState({ showForm: false });
  }

  render() {
    return(
      <StaticQuery
        query={graphql`
          query SiteIndexQuery {
            site {
              siteMetadata {
                menuLinks {
                  name
                  link
                }
              }
            }
          }
        `}
        render={data => (
      <Layout>
        <Menubar scrollLinks={scrollLinks} menuLinks={[]} />
        <header id="header">
          <div className="content">
            <h1>
              {config.heading}
            </h1>
            <p>
              {config.subHeading}
              <span style={{display: "block"}} id="coming-soon">Coming soon</span>
            </p>
            <Scroll type="id" element="subscribe">
              <a href="#subscribe" className="button primary">
                Get notified!
              </a>
            </Scroll>
          </div>
          <img src={small_icon} alt="" />
          <Scroll type="id" element="about">
            <a href="#about" id="learn-more" className="button icon fa-chevron-down">
              Learn More
            </a>
          </Scroll>
        </header>

        <section id="about" className="wrapper style2">
          <header className="major">
            <h2>Focus on your tasks</h2>
          </header>
          <div className="inner">
            <img src={demo} alt="" style={{display: "block", margin: "0 auto"}}/><br />
            <p>Cleave is an application for macOS that allows you to explicitly change your working context.</p>
            <p>Done with work for the day? Pull up Cleave, switch to another context (Apartment-hunting and budgeting? Shopping and listening to music?) – and watch all your applications and their various windows and tabs close; their state safely saved for later.</p>
          </div>
        </section>

        <section id="use" className="wrapper special">
          <header className="major">
            <h2>Available anywhere</h2>
          </header>
          <img src={popup} alt="" />
          <p>Bring up Cleave's Command Palette with the global shortcut:</p>
          <p><code>⌥</code>+<code>⌘</code>+<code>P</code></p>
          <p>This lets you fuzzy-find any action from anywhere.</p>

          <div className="menubarWrapper">
            <div className="menuWallpaper">
              <div className="menubg" />
              <img src={menubar} alt="" className="menuBar" />
            </div>
          </div>
          <p>All actions are also available in a dropdown menu from the axe icon in your menu bar.</p>
        </section>

        <section id="subscribe" className="wrapper style2 special">
          <header className="major">
            <h2>Get notified</h2>
          </header>

            { this.state.showForm ?
              <div id="mc_embed_signup">
                <p>An open Beta of Cleave will be out this autumn.<br/>Sign up, and be the first to know!</p>
                <form action="https://eivindarvesen.us20.list-manage.com/subscribe/post?u=8a5acb4b18746e4cae6080717&amp;id=5e0c76f881" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate ref={this.formRef} onSubmit={this.handleForm}>
                  <div id="mc_embed_signup_scroll">
                    <input style={{'maxWidth': '350px', display:'inline-block'}} type="email" defaultValue="" name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required></input>
                    <div hidden aria-hidden="true">
                      <input type="text" name="b_8a5acb4b18746e4cae6080717_5e0c76f881" tabIndex="-1" defaultValue=""></input>
                    </div>
                    <br /><br />
                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button primary"></input>
                  </div>
                </form>
              </div>
              : <div><strong>Thanks for signing up!</strong><p>We'll let you know when Cleave is available.</p></div>
            }

        </section>

        <Footer />
      </Layout>
      )}
          />
    );
  }
}

export default IndexPage;
